import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import cut_bukuro from "../../assets/cut_bukuro.webp";
import { BaseButton } from "../../components/Button/BaseButton";
import { VideoOverlay } from "../../components/Overlay/VideoOverlay";
import { PrizeList } from "../../components/PrizeList/PrizeList";
import { Space } from "../../components/Space/Space";
import { Prize } from "../../data/initialPrizes";
import "./Result.css";

const Result = () => {
  const { index } = useParams();
  const navigate = useNavigate();
  const [latestDrawnPrizes, setLatestDrawnPrizes] = useState<Prize[]>([]);
  const [showOverlay, setShowOverlay] = useState(true);
  const [hasSpecialPrize, setHasSpecialPrize] = useState(false);
  const [latestWChanceResult, setLatestWChanceResult] = useState(false);
  const [remainingPrizesTotal, setRemainingPrizesTotal] = useState(0);
  const [openCutBukuro, setOpenCutBukuro] = useState(false);

  const videoSrc = {
    S: require("../../assets/kujiVideo/special.mp4"),
    Normal: require("../../assets/kujiVideo/normal.mp4"),
  };

  // 最新の抽選結果を取得
  useEffect(() => {
    const storedPrizes = localStorage.getItem(`latestDrawnPrizes-${index}`);
    if (storedPrizes) {
      try {
        const parsedPrizes = JSON.parse(storedPrizes);
        setLatestDrawnPrizes(parsedPrizes);
      } catch (error) {
        console.error("Error parsing localStorage data:", error);
      }
    }

    // 最新のWチャンスの当選結果を取得
    const latestResult = localStorage.getItem(`latestWChanceResult-${index}`);
    setLatestWChanceResult(latestResult ? JSON.parse(latestResult) : false);

    // 残りのくじ枚数を取得
    const storedRemainingPrizes = localStorage.getItem(`prizes-${index}`);
    if (storedRemainingPrizes) {
      const parsedRemainingPrizes = JSON.parse(storedRemainingPrizes);
      const remainingTotal = parsedRemainingPrizes.reduce(
        (acc: number, prize: Prize) => acc + prize.count,
        0
      );
      setRemainingPrizesTotal(remainingTotal);
    }
  }, [index]);

  // 最新の抽選結果にS賞が含まれているか判定
  useEffect(() => {
    const hasSpecialPrize = latestDrawnPrizes.some(
      (prize) => prize.type === "S"
    );
    setHasSpecialPrize(hasSpecialPrize);
  }, [latestDrawnPrizes]);

  // オーバーレイを閉じる関数
  const closeOverlay = () => {
    setShowOverlay(false);
  };

  // 最新の抽選結果から、賞品ごとの当選回数をカウント、ソート
  const prizes = latestDrawnPrizes
    .reduce((acc, prize) => {
      const existingPrize = acc.find((p) => p.name === prize.name);
      if (existingPrize) {
        existingPrize.count++;
      } else {
        acc.push({ ...prize, count: 1 });
      }
      return acc;
    }, [] as (Prize & { count: number })[])
    .sort((a, b) => {
      if (a.type === "S" && b.type !== "S") return -1;
      if (a.type !== "S" && b.type === "S") return 1;
      return a.index - b.index;
    });

  // S賞とN賞に分ける
  const sPrizes = prizes.filter((prize) => prize.type === "S");
  const nPrizes = prizes.filter((prize) => prize.type === "N");

  return (
    <>
      {showOverlay && (
        <VideoOverlay
          canPlay={showOverlay}
          isOpen={showOverlay}
          onClose={closeOverlay}
          hasSpecial={hasSpecialPrize}
          videoSrc={videoSrc}
        />
      )}
      <>
        <Space height={16} />
        <div className="title-h2-container">
          <h2 className="title-h2">Result</h2>
        </div>
        <Space height={16} />
        <>
          <PrizeList prizes={sPrizes} title="Special" titleClass="title-s" />
          <Space height={16} />
          <PrizeList prizes={nPrizes} title="Normal" titleClass="title-n" />
        </>
      </>
      <Space height={16} />
      <>
        <Space height={16} />
        <div className="title-h2-container">
          <h2 className="title-h2">Bonus</h2>
        </div>
        <Space height={16} />
        <div className="genga-container" onClick={() => setOpenCutBukuro(true)}>
          <img
            src={cut_bukuro}
            alt="カット袋"
            className={`cut_bukuro ${openCutBukuro ? "visible" : ""}`}
          />
          {latestWChanceResult ? (
            <img
              src={require(`../../data/wchance/${index}.png`)}
              alt="原画画像"
              className="cover-genga"
            />
          ) : (
            <img
              src={require("../../assets/hazure.png")}
              alt="デフォルト原画画像"
              className="cover-genga"
            />
          )}
        </div>
      </>
      <Space height={16} />
      <>
        {remainingPrizesTotal === 0 && (
          <>
            <Space height={16} />
            <div className="title-h2-container">
              <h2 className="title-h2">Last Play</h2>
            </div>
            <Space height={16} />
            <img
              src={require(`../../data/last/${index}.png`)}
              alt="原画画像"
              className="cover-genga"
            />
          </>
        )}
      </>
      <Space height={32} />
      <BaseButton
        onClick={() => navigate(`/oddtaxi/${index}`)}
        title="Back"
        backgroundColor="#c9e900"
        textColor="#000"
      />
      <Space height={32} />
    </>
  );
};

export default Result;
