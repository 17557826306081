import { memo } from "react";
import { useLocation } from "react-router-dom";

import s_icon from "../../assets/special/s_icon.webp";
import { Space } from "../Space/Space";

interface Props {
  prizes: { name: string; type: string; count: number; index: number }[];
  title: string;
  titleClass: string;
}

export const PrizeList = memo(({ prizes, title, titleClass }: Props) => {
  const location = useLocation();

  return (
    <>
      {!location.pathname.includes("/result") && (
        <>
          <div className="title-h2-container">
            <h2 className={titleClass}>{title}</h2>
          </div>
          <Space height={16} />
        </>
      )}
      <div className="gengas row-2">
        {prizes.map((prize, index) => (
          <div key={`${prize.type}-${index}`}>
            <div className="genga-container">
              {prize.type === "S" && (
                <>
                  <img
                    src={s_icon}
                    alt="S賞アイコン"
                    className="s-icon"
                    style={{
                      filter: prize.count === 0 ? "brightness(75%)" : "none",
                    }}
                  />
                </>
              )}
              <img
                src={require(`../../data/cover/${prize.index}.png`)}
                alt="原画画像"
                className="cover-genga"
                style={{
                  filter: prize.count === 0 ? "brightness(75%)" : "none",
                }}
              />
            </div>
            <Space height={16} />
            <span>
              {`${prize.name}`}
              <br />
              {location.pathname.includes("/result")
                ? `Count： ${prize.count}`
                : `Rest： ${prize.count}`}
            </span>
          </div>
        ))}
      </div>
    </>
  );
});
