import "./BaseButton.css";

import { memo } from "react";

interface Props {
  title?: string;
}

export const DisabledButton = memo(({ title }: Props) => {
  return <button className="base-button disabled-button">{title}</button>;
});
