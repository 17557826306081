import "./BaseButton.css";

import { memo } from "react";

import { Loading } from "../Loading/Loading";

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  backgroundColor?: string;
  textColor?: string;
  width?: string;
  border?: string;
  isLoading?: boolean;
  gaEvent?: string;
}

export const BaseButton = memo(
  ({
    onClick,
    title,
    backgroundColor = "#ff4127",
    textColor = "#fff",
    width = "100%",
    border,
    isLoading = false,
  }: Props) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(e);
      }
    };

    return (
      <button
        className={`base-button ${isLoading ? "disabled" : ""}`}
        onClick={handleClick}
        style={{ backgroundColor, color: textColor, width, border }}
        disabled={isLoading}
      >
        {isLoading ? <Loading /> : title}
      </button>
    );
  }
);
