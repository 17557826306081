import "./NotFound.css";

import { Link } from "react-router-dom";

import { Border } from "../../components/Border/Border";
import { BaseButton } from "../../components/Button/BaseButton";
import { Space } from "../../components/Space/Space";

const NotFound = () => {
  return (
    <>
      <div className="notfound-container">
        <div className="notfound">
          <h2>404 NOT FOUND</h2>
          <Space height={32} />
          <Border />
          <Space height={32} />
          <Link to="/">
            <BaseButton
              title="Top"
              backgroundColor="#c9e900"
              textColor="#000"
            />
          </Link>
          <Space height={44} />
        </div>
      </div>
    </>
  );
};

export default NotFound;
