import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { ScrollToTop } from "./components/ScrollToTop/ScrollToTop";
import { Space } from "./components/Space/Space";
import Box from "./pages/Box/Box";
import BoxList from "./pages/BoxList/BoxList";
import NotFound from "./pages/NotFound/NotFound";
import Result from "./pages/Result/Result";
import "./App.css";

const Layout = () => {
  return (
    <>
      <Header />
      <div className="app">
        <Space height={56} />
        <Routes>
          <Route path="/" element={<Navigate to="/oddtaxi" replace />} />
          <Route path="/oddtaxi" element={<BoxList />} />
          <Route path="/oddtaxi/:index" element={<Box />} />
          <Route path="/oddtaxi/:index/result" element={<Result />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
};

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Layout />
      </Router>
    </>
  );
};

export default App;
