export interface Prize {
  name: string;
  count: number;
  index: number;
  type: "S" | "N";
}

export const initialPrizes: Prize[] = [
  { name: "Episode 1 c_253", count: 5, type: "N", index: 0 },
  { name: "Episode 2 c_103", count: 5, type: "N", index: 1 },
  { name: "Episode 2 c_162", count: 2, type: "S", index: 2 },
  { name: "Episode 3 c_22", count: 5, type: "N", index: 3 },
  { name: "Episode 3 c_139", count: 2, type: "S", index: 4 },
  { name: "Episode 4 c_176", count: 5, type: "N", index: 5 },
  { name: "Episode 6 c_63", count: 5, type: "N", index: 6 },
  { name: "Episode 6 c_214", count: 5, type: "N", index: 7 },
  { name: "Episode 8 c_129", count: 5, type: "N", index: 8 },
  { name: "Episode 8 c_174", count: 5, type: "N", index: 9 },
  { name: "Episode 9 c_23", count: 5, type: "N", index: 10 },
  { name: "Episode 9 c_105", count: 5, type: "N", index: 11 },
  { name: "Episode 9 c_119", count: 5, type: "N", index: 12 },
  { name: "Episode 10 c_89", count: 5, type: "N", index: 13 },
  { name: "Episode 10 c_90", count: 5, type: "N", index: 14 },
  { name: "Episode 10 c_157", count: 2, type: "S", index: 15 },
  { name: "Episode 11 c_47", count: 5, type: "N", index: 16 },
  { name: "Episode 11 c_73", count: 5, type: "N", index: 17 },
  { name: "Episode 11 c_110", count: 5, type: "N", index: 18 },
  { name: "Episode 11 c_254", count: 5, type: "N", index: 19 },
  { name: "Episode 12 c_261", count: 5, type: "N", index: 20 },
];
