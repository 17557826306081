import "./Overlay.css";
import { memo, useEffect } from "react";

type VideoSRC = {
  S: string;
  Normal: string;
};

interface Props {
  canPlay: boolean;
  isOpen?: boolean;
  onClose: () => void;
  hasSpecial: boolean;
  videoSrc: VideoSRC;
}

export const VideoOverlay = memo(
  ({ canPlay, onClose, hasSpecial, videoSrc }: Props) => {
    const handleVideoEnd = () => {
      onClose();
    };

    useEffect(() => {
      const videoElement = document.getElementById("video") as HTMLVideoElement;
      if (canPlay) {
        videoElement.play();
      }
    }, [canPlay]);

    return (
      <div className="video-overlay">
        <video
          id="video"
          muted={true}
          src={hasSpecial ? videoSrc.S : videoSrc.Normal}
          onEnded={handleVideoEnd}
          controls={false}
          className="video-player"
          playsInline
          autoPlay
        />
        <button className="skip-button" onClick={onClose}>
          Skip
        </button>
      </div>
    );
  }
);
