import "./YubiButton.css";
import { memo } from "react";

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: string;
  title: string;
  backgroundColor?: string;
  textColor?: string;
  width?: string;
  border?: string;
  isLoading?: boolean;
  gaEvent?: string;
}

export const YubiButton = memo(
  ({
    onClick,
    title,
    backgroundColor = "#ff4127",
    textColor = "#fff",
    width = "100%",
    border,
    isLoading = false,
    icon = require("../../assets/icon/yubi.webp"),
    gaEvent = `${title}/${document.title}`,
  }: Props) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(e);
      }
    };
    return (
      <button
        className={`yubi-button ${isLoading ? "disabled" : ""}`}
        onClick={handleClick}
        style={{ backgroundColor, color: textColor, width, border }}
        disabled={isLoading}
      >
        <>
          <img src={icon} alt="icon" />
          <span className="yubi-button-span">{title}</span>
        </>
      </button>
    );
  }
);
