import "./Footer.css";
import { Space } from "../Space/Space";

export const Footer = () => {
  return (
    <div className="footer">
      <Space height={16} />
      <div className="footer-container">
        <img
          src={require("../../assets/logo/logo.webp")}
          alt="ロゴ"
          className="w-100"
        />
        <Space height={32} />
        <img
          src={require("../../assets/logo/footer-logo.webp")}
          alt="ロゴ"
          className="w-100"
        />
        <Space height={32} />
        <p>(C) GENGAKUJI</p>
        <Space height={64} />
      </div>
    </div>
  );
};
