import "./Loading.css";

interface Props {
  borderColor?: string;
  topColor?: string;
}

export const Loading = ({
  borderColor = "rgba(255, 255, 255, 0.3)",
  topColor = "#fff",
}: Props) => {
  return (
    <div
      className="loading"
      style={{
        borderColor: `${borderColor}`,
        borderTopColor: `${topColor}`,
      }}
    />
  );
};
