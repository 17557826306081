import { memo, useState } from "react";

import { DisabledButton } from "../Button/DisabledButton";
import { YubiButton } from "../Button/YubiButton";
import { Space } from "../Space/Space";
import "./Popup.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDraw: (count: number) => void;
  maxDraws: number;
}

export const Popup = memo(({ isOpen, onClose, onDraw, maxDraws }: Props) => {
  const [selectedCount, setSelectedCount] = useState(0);

  const handleDraw = () => {
    onDraw(selectedCount);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="popup">
      <div className="popup-content">
        <h2>Select the number of draws</h2>
        <Space height={48} />
        <select
          id="drawCountPopup"
          value={selectedCount}
          onChange={(e) => setSelectedCount(Number(e.target.value))}
        >
          <option value={0}>Select</option>
          {Array.from({ length: maxDraws }, (_, i) => i + 1).map((number) => (
            <option key={number} value={number}>
              {number}
            </option>
          ))}
        </select>
        <Space height={48} />
        {selectedCount > 0 ? (
          <YubiButton onClick={handleDraw} title="Draw" />
        ) : (
          <DisabledButton title="Draw" />
        )}
        <Space height={32} />
        <span onClick={onClose} className="cancel">
          Cancel
        </span>
      </div>
    </div>
  );
});
