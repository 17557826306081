import "./Card.css";
import { memo } from "react";
import { Link } from "react-router-dom";

import { Space } from "../Space/Space";

interface Props {
  index: string;
  remainingPrizesTotal: number;
  hasWonWChance: boolean;
}

export const BoxCard = memo(
  ({ index, remainingPrizesTotal, hasWonWChance }: Props) => {
    return (
      <Link to={`/oddtaxi/${index}`}>
        <div className="card">
          <Space height={24} />
          <span>BOX{index}</span>
          <Space height={24} />
          <p>Rest: {remainingPrizesTotal} / 96</p>
          <p>Bonus: {hasWonWChance ? "Won" : "Not Won"}</p>
          <Space height={24} />
        </div>
      </Link>
    );
  }
);
