// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD7Mn-hWmpxOJzFBLTyVV9JSL2klsD9wWE",
  authDomain: "ax2024-oddtaxi.firebaseapp.com",
  projectId: "ax2024-oddtaxi",
  storageBucket: "ax2024-oddtaxi.appspot.com",
  messagingSenderId: "646484661011",
  appId: "1:646484661011:web:90b7b2d9f6ac66b319c0e6",
  measurementId: "G-6WQLXS300V",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
