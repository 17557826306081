import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";

import { BaseButton } from "../../components/Button/BaseButton";
import { BoxCard } from "../../components/Card/BoxCard";
import { Space } from "../../components/Space/Space";
import { Prize, initialPrizes } from "../../data/initialPrizes";
import { db } from "../../firebaseConfig";

const BoxList = () => {
  const boxIndices = ["1", "2", "3", "4", "5"];
  const [boxData, setBoxData] = useState<{
    [key: string]: {
      remainingPrizesTotal: number;
      hasWonWChance: boolean;
    };
  }>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchBoxData = () => {
      const data: {
        [key: string]: {
          remainingPrizesTotal: number;
          hasWonWChance: boolean;
        };
      } = {};
      boxIndices.forEach((index) => {
        const storedRemainingPrizes = localStorage.getItem(`prizes-${index}`);
        const remainingPrizesTotal = storedRemainingPrizes
          ? JSON.parse(storedRemainingPrizes).reduce(
              (acc: number, prize: any) => acc + prize.count,
              0
            )
          : 96;
        const hasWonWChance =
          localStorage.getItem(`wChanceWin-${index}`) === "true";
        data[index] = { remainingPrizesTotal, hasWonWChance };
      });
      setBoxData(data);
    };
    fetchBoxData();
  }, []);

  // Firebaseにデータを保存する関数
  const saveToFirebase = async () => {
    setIsLoading(true);
    try {
      // 抽選履歴の保存
      const drawHistory = JSON.parse(
        localStorage.getItem("drawHistory") || "[]"
      );
      const drawHistoryCollectionRef = collection(db, "drawHistory");
      const querySnapshot = await getDocs(drawHistoryCollectionRef);
      const deletePromises = querySnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);
      for (const drawResult of drawHistory) {
        await addDoc(drawHistoryCollectionRef, {
          uid: drawResult.uid,
          createdAt: drawResult.createdAt,
          productId: drawResult.productId,
          boxId: drawResult.boxId,
          count: drawResult.count,
          result: drawResult.result.map((prize: Prize) => ({
            name: prize.name,
            type: prize.type,
            index: prize.index,
          })),
        });
      }
      console.log("Draw history saved to Firebase!");

      // Boxごとの在庫状況とWチャンスの当選有無の保存
      for (let i = 1; i <= 5; i++) {
        const boxId = i;
        const storedPrizes = localStorage.getItem(`prizes-${i}`);
        const prizes = storedPrizes ? JSON.parse(storedPrizes) : initialPrizes;
        const remainingPrizesTotal = prizes.reduce(
          (acc: number, prize: Prize) => acc + prize.count,
          0
        );
        const hasWonWChance = JSON.parse(
          localStorage.getItem(`wChanceWin-${i}`) || "false"
        );
        await setDoc(doc(db, "inventory", i.toString()), {
          boxId: boxId,
          prizes: prizes.map((prize: Prize) => ({
            name: prize.name,
            type: prize.type,
            count: prize.count,
            index: prize.index,
          })),
          rest: remainingPrizesTotal,
          wChance: hasWonWChance,
        });
      }
      console.log("Inventory and Bonus win status saved to Firebase!");
    } catch (error) {
      console.error("Error saving data to Firebase:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {boxIndices.map((index) => (
        <BoxCard
          key={index}
          index={index}
          remainingPrizesTotal={boxData[index]?.remainingPrizesTotal ?? 96}
          hasWonWChance={boxData[index]?.hasWonWChance || false}
        />
      ))}
      <Space height={32} />
    </div>
  );
};

export default BoxList;
