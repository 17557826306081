import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { BaseButton } from "../../components/Button/BaseButton";
import { YubiButton } from "../../components/Button/YubiButton";
import { Popup } from "../../components/Popup/Popup";
import { PrizeList } from "../../components/PrizeList/PrizeList";
import { Space } from "../../components/Space/Space";
import { Prize, initialPrizes } from "../../data/initialPrizes";
import "./Box.css";

export interface DrawResult {
  uid: string;
  createdAt: string;
  productId: string;
  boxId: string;
  count: number;
  result: Prize[];
}

const Box = () => {
  const { index } = useParams();
  const [prizes, setPrizes] = useState<Prize[]>(initialPrizes);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  const [hasWonWChance, setHasWonWChance] = useState(false);
  const isAllPrizesDrawn = prizes.every((prize) => prize.count === 0);
  const remainingPrizesTotal = prizes.reduce(
    (acc, prize) => acc + prize.count,
    0
  );

  // 初期レンダリング時に、ローカルストレージから賞品在庫を取得
  useEffect(() => {
    const storedPrizes = localStorage.getItem(`prizes-${index}`);
    if (storedPrizes) {
      setPrizes(JSON.parse(storedPrizes));
    }

    // そのBoxでWチャンスが当選済みかどうかを取得
    const hasWon = localStorage.getItem(`wChanceWin-${index}`);
    setHasWonWChance(hasWon ? JSON.parse(hasWon) : false);
  }, [index]);

  // 指定された回数、抽選する関数
  const drawPrizes = (count: number) => {
    let updatedPrizes = [...prizes];
    let drawnPrizes: Prize[] = [];

    for (let i = 0; i < count; i++) {
      const availablePrizes = updatedPrizes.filter((prize) => prize.count > 0);
      const randomIndex = Math.floor(Math.random() * availablePrizes.length);
      const drawnPrize = availablePrizes[randomIndex];

      // 抽選された賞品の在庫を1減らす
      updatedPrizes = updatedPrizes.map((prize) =>
        prize.name === drawnPrize.name
          ? { ...prize, count: prize.count - 1 }
          : prize
      );

      drawnPrizes.push(drawnPrize);
    }

    setPrizes(updatedPrizes);

    // 更新された賞品在庫をローカルストレージに保存
    localStorage.setItem(`prizes-${index}`, JSON.stringify(updatedPrizes));

    // 最新の抽選結果をローカルストレージに保存
    localStorage.setItem(
      `latestDrawnPrizes-${index}`,
      JSON.stringify(drawnPrizes)
    );

    // 最新のWチャンスの当選結果を保存する関数
    const saveLatestWChanceResult = (boxIndex: string, hasWon: boolean) => {
      localStorage.setItem(
        `latestWChanceResult-${boxIndex}`,
        JSON.stringify(hasWon)
      );

      // 抽選履歴をローカルストレージに保存
      const now = new Date().toISOString();
      const drawId = Date.now().toString();
      const drawResult = {
        uid: drawId,
        createdAt: now,
        productId: "oddtaxi",
        boxId: index,
        count,
        result: drawnPrizes,
      };

      const drawHistory = JSON.parse(
        localStorage.getItem("drawHistory") || "[]"
      );
      drawHistory.push(drawResult);
      localStorage.setItem("drawHistory", JSON.stringify(drawHistory));
    };

    // Wチャンスの当選判定
    const hasWonWChance = getWChanceWin(index!);
    //もしWチャンスが当選していない場合
    if (!hasWonWChance) {
      const wChanceProbability = count / remainingPrizesTotal;
      const isWChanceWin = Math.random() < wChanceProbability;
      saveLatestWChanceResult(index!, isWChanceWin); // 最新のWチャンスの当選結果を保存
      if (isWChanceWin) {
        saveWChanceWin(index!, true); // そのBoxでWチャンスが当選済みであることを保存
      }
    } else {
      saveLatestWChanceResult(index!, false); // 過去に当選済みなら最新の結果は必ず非当選
    }

    navigate("result");
  };

  // 賞品データを初期状態にリセットする関数
  const resetPrizes = () => {
    setPrizes(initialPrizes);
    localStorage.setItem(`prizes-${index}`, JSON.stringify(initialPrizes));
    localStorage.removeItem(`latestDrawnPrizes-${index}`);
    saveWChanceWin(index!, false);

    // Historyのリセット
    const storedDrawHistory = localStorage.getItem("drawHistory");
    if (storedDrawHistory) {
      const parsedDrawHistory: DrawResult[] = JSON.parse(storedDrawHistory);
      const filteredDrawHistory = parsedDrawHistory.filter(
        (result) => result.boxId !== index
      );
      localStorage.setItem("drawHistory", JSON.stringify(filteredDrawHistory));
    }
  };

  // Wチャンスの当選状況を保存する関数
  const saveWChanceWin = (boxIndex: string, hasWon: boolean) => {
    localStorage.setItem(`wChanceWin-${boxIndex}`, JSON.stringify(hasWon));
  };

  // Wチャンスの当選状況を取得する関数
  const getWChanceWin = (boxIndex: string): boolean => {
    const hasWon = localStorage.getItem(`wChanceWin-${boxIndex}`);
    return hasWon ? JSON.parse(hasWon) : false;
  };

  return (
    <>
      <>
        <Space height={16} />
        <>
          <div className="title-h2-container">
            <h2 className="title-h2">BOX{index}</h2>
          </div>
        </>
        <Space height={16} />
        <h3>Rest：{remainingPrizesTotal}/96</h3>
        <Space height={16} />
        <>
          <PrizeList
            prizes={prizes.filter((prize) => prize.type === "S")}
            title="Special"
            titleClass="title-s"
          />
          <Space height={16} />
          <PrizeList
            prizes={prizes.filter((prize) => prize.type === "N")}
            title="Normal"
            titleClass="title-n"
          />
        </>
      </>
      <Space height={16} />
      <>
        <>
          <div className="title-h2-container">
            <h2 className="title-h2">Bonus</h2>
          </div>
        </>
        <Space height={16} />
        <img
          src={require(`../../data/wchance/${index}.png`)}
          alt="原画画像"
          className="cover-genga"
          style={{ filter: hasWonWChance ? "brightness(75%)" : "none" }}
        />
      </>
      <Space height={16} />
      <>
        <>
          <div className="title-h2-container">
            <h2 className="title-h2">Last Play</h2>
          </div>
        </>
        <Space height={16} />
        <img
          src={require(`../../data/last/${index}.png`)}
          alt="原画画像"
          className="cover-genga"
          style={{
            filter: remainingPrizesTotal === 0 ? "brightness(75%)" : "none",
          }}
        />
      </>
      <Space height={32} />
      <BaseButton
        onClick={() => navigate(`/oddtaxi`)}
        title="Back"
        backgroundColor="#c9e900"
        textColor="#000"
      />
      <Space height={32} />
      <>
        <div className="fixed-bottom">
          {isAllPrizesDrawn ? (
            <BaseButton
              onClick={resetPrizes}
              title="Reset"
              backgroundColor="#15c0ed"
              textColor="#000"
            />
          ) : (
            <YubiButton onClick={() => setIsPopupOpen(true)} title="Draw" />
          )}
        </div>
      </>
      <Popup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onDraw={drawPrizes}
        maxDraws={remainingPrizesTotal}
      />
    </>
  );
};

export default Box;
