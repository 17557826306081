import "./Header.css";

import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <div className="header">
      <div className="header-container">
        <div className="header-logo">
          <Link to="/" className="header-logo-link">
            <img
              src={require("../../assets/logo/header-logo.webp")}
              alt="ロゴ"
              width="100%"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
